<template>
  <div>
    <div class="container-top">
      <div class="pc container padding-top-60 position-relative">
        <div class="h5 main">서비스 개설 신청</div>
        <div class="flex padding-top-52" style="align-items: flex-start">
          <!-- 서비스 정보 -->
          <div class="col-9" style="padding-right:72px">
            <div class="lp-divider-main"></div>
            <div class="padding-top-16 body0-bold main" id="service-info">서비스 정보</div>
            <div class="lp-divider-gray2 padding-top-16"></div>
            <!--<div class="flex padding-top-24" v-if="order.type==='theme'">
              <div class="service-info-title subtitle5">서비스 이름</div>
              <div class="service-info-content">
                <input class="input font-primary" type="text"
                       placeholder="서비스 이름을 입력하세요."
                       style="width:300px" maxlength="30"
                       v-model="order.service_name"/>
              </div>
            </div>-->
            <div class="flex padding-top-24" v-if="order.type==='quickbuilder'">
              <div class="service-info-title subtitle5">서비스 도메인</div>
              <div class="service-info-content">{{ order.key }}.launchpack.co.kr
                <div class="flex-align" style="margin-top:4px">
                  <img src="/static/icon/fi_alert-circle.svg" class="svg-primary" style="width:20px;height:20px;margin-right:6px">
                  <div class="body4 sub3">서비스 개설 후 추가 도메인 등록 가능합니다. (도메인 구입/등록 비용 별도)</div>
                </div>
              </div>
              <!--<div class="flex-align" v-else>
                <input class="input font-primary" type="text"
                       placeholder="영문,숫자만 입력 가능합니다."
                       style="width:300px" maxlength="20"
                       v-model="order.key" @input="onKeyupServiceId"/>
                <div class="body2 sub2 margin-left-12">.launchpack.co.kr</div>
              </div>-->
            </div>
            <!-- quickbuilder -->
            <template v-if="order.type === 'quickbuilder'">
              <div class="flex padding-top-24" v-for="(opt, optIdx) in sbOption" :key="'sb-opt-'+optIdx">
                <div class="service-info-title subtitle5">{{ opt.name }}</div>
                <div class="service-info-content">
                  <div v-for="(item,idx) in order.service[opt.type]" :key="'conversion-'+idx" class="flex-align" style="margin-bottom:8px">
                    <div class="body2 sub" style="margin-right:28px">{{ item.name }}</div>
                    <div><span class="price body2-bold">{{ item.add_option ? item.option.price.price : item.price.price|currencyNum }}</span>원<span class="body4 sub3" v-if="item.price.is_subscription">/월</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex padding-top-24">
                <div class="service-info-title subtitle5">서비스 미리보기</div>
                <div class="service-info-content" style="display: grid;grid-template-columns: repeat(2, 1fr);grid-column-gap: 16px">
                  <button class="button is-primary-light2 body2-medium" @click="openPreview">
                    <img class="icon-device" src="/static/img/ic_device_mobile.svg">모바일 미리보기</button>
                  <a :href="`https://sbpreview.launchpack.co.kr/super_builder/preview?key=${order.key}&id=${order.service.id}&device=pc&package=${order.service.conversions[0].key}`" target="_blank">
                    <button class="button is-primary-light2 body2-medium" style="width:100%">
                      <img class="icon-device" src="/static/img/ic_device_pc.svg">데스크톱 미리보기</button>
                  </a>
                </div>
              </div>
            </template>

            <!-- 테마일 경우  -->
            <div v-if="order.type==='theme'">
              <div class="flex padding-top-24">
                <div class="service-info-title subtitle5">상품</div>
                <div class="service-info-content">
                  <template v-for="(option, option_idx) in order.order_product.order_product_option">
                    <div class="flex-between" style="align-items: flex-start" :key="`option-${option_idx}`" v-if="option.option_type===0">
                      <div class="body2" style="width:300px" v-html="optionName(option)"></div>
                      <div class="flex-align margin-left-28" v-if="optionPrice(option)>0">
                        <div class="body2-bold">{{ optionPrice(option) / order.date_unit | currencyNum }}</div>
                        <span class="body2 sub">원</span>
                        <span class="body2 sub3" v-if="isSubscription(option, order.order_product.product)">/월</span>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <template v-if="isAddOption(order.order_product.order_product_option)">
                <div class="lp-divider-gray2 padding-top-24"></div>
                <div class="flex padding-top-24">
                <div class="service-info-title subtitle5">추가옵션</div>
                <div class="service-info-content">
                  <template v-for="(option, option_idx) in order.order_product.order_product_option">
                    <div class="flex-between margin-bottom-12" style="align-items: flex-start" v-if="option.option_type===1" :key="`option-${option_idx}`">
                      <div class="body2" style="width:300px" v-html="optionName(option)"></div>
                      <div class="flex-align margin-left-28" v-if="optionPrice(option)>0">
                        <div class="body2-bold">{{ optionPrice(option) | currencyNum }}</div>
                        <span class="body2 sub">원</span>
                        <span class="body2 sub3" v-if="isSubscription(option, order.order_product.product)">/월</span>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              </template>
            </div>

            <!-- 호스팅 -->
            <select-hosting :value.sync="order.hosting"></select-hosting>

            <!-- 케어 서비스 -->
            <div class="lp-divider-main" style="padding-top:96px"></div>
            <div class="padding-top-16 flex-align">
              <div class="body0-bold main">케어서비스</div>
            </div>
            <div class="lp-divider-gray2 padding-top-16"></div>
            <div class="body2 sub2" style="margin-top:96px">
              고객이 전문가 없이도 원활한 서비스 운영이 가능하도록<br>
              서비스 수정, 운영, 에러의 안정적인 해결 등<br>
              기획, 개발, 디자인 등의 추가 용역 제공을 충전금을 통해  제공하는 서비스 입니다.<br>
              <br>
              별도 문의를 통해 이용할 수 있습니다.
            </div>
            <select-care-service :value.sync="order.care_service"></select-care-service>

            <div class="lp-divider-main" style="padding-top:96px"></div>
            <div class="body0-bold main margin-top-16">개설 신청자 정보</div>
            <div class="flex-align padding-top-24">
              <div class="service-info-title subtitle5">신청자</div>
              <div class="service-info-content body2">{{ order.name }}</div>
            </div>
            <div class="flex-align padding-top-24">
              <div class="service-info-title subtitle5">연락처</div>
              <div class="service-info-content body2">{{ order.phone }}</div>
            </div>
            <div class="flex-align padding-top-24">
              <div class="service-info-title subtitle5">이메일</div>
              <div class="service-info-content body2">{{ order.email }}</div>
            </div>

            <div class="flex padding-top-40">
              <img style="width:18px;height:20px" class="svg-primary" src="/static/icon/fi_alert-circle.svg">
              <div class="body4 margin-left-4 sub3" id="info">요금제 만료 14일 전 / 7일 전 / 만료 당일에 개설 신청자 연락처로 문자 혹은 이메일 안내 드립니다. 만료 전까지 연장 결제가 이루어지지 않을 시 서비스 기능 이용이 제한되오니 유의하시기 바랍니다.</div>
            </div>
          </div>
          <div class="col-3 price-info-wrapper" :class="`${sticky}`">
            <div class="h8 main">결제금액</div>
            <div class="lp-divider-gray1 padding-top-16"></div>
            <!-- quickbuilder -->
            <template v-if="order.type === 'quickbuilder'">
              <div class="flex-between padding-top-16">
                <div class="subtitle7">상품</div>
                <div>
                  <span class="body4-bold">{{ conversionPrice | currencyNum }}</span>
                  <span class="body4 main">원</span>
                </div>
              </div>
              <div class="lp-divider-gray3 padding-top-16"></div>
              <div class="flex-between padding-top-16">
                <div class="subtitle7">추가 플러그인</div>
                <div>
                  <span class="body4-bold">+{{ pluginPrice | currencyNum }}</span>
                  <span class="body4 main">원</span>
                </div>
              </div>
              <div class="lp-divider-gray3 padding-top-16"></div>
            </template>

            <template v-if="order.type === 'theme'">
              <div class="flex-between padding-top-16">
                <div class="subtitle7">상품</div>
                <div>
                  <span class="body4-bold">{{ order.order_product.required_option_price / order.date_unit | currencyNum }}</span>
                  <span class="body4 main">원</span><span class="body4 sub3" v-if="order.type !== 'quickbuilder'">/월</span>
                </div>
              </div>
              <template v-if="order.order_product.add_option_price>0">
              <div class="lp-divider-gray3 padding-top-16"></div>
              <div class="flex-between padding-top-16">
                <div class="subtitle7">추가옵션</div>
                <div>
                  <span class="body4-bold">{{ order.order_product.add_option_price | currencyNum }}</span>
                  <span class="body4 main">원</span><span class="body4 sub3" v-if="order.type !== 'quickbuilder'">/월</span>
                </div>
              </div>
              </template>
              <div class="lp-divider-gray3 padding-top-16"></div>
            </template>
            <div class="flex-between padding-top-16">
              <div class="subtitle7">호스팅</div>
              <div>
                <span class="body4-bold">+{{ hostingPrice | currencyNum }}</span>
                <span class="body4 main">원</span><span class="body4 sub3" v-if="order.type !== 'quickbuilder'">/월</span>
              </div>
            </div>
            <div class="lp-divider-gray3 padding-top-16"></div>

            <div class="flex-between padding-top-16">
              <div class="subtitle7">케어 서비스</div>
              <div>
                <span class="body4-bold">+{{ carePrice | currencyNum }}</span>
                <span class="body4 main">원</span><span class="body4 sub3" v-if="order.type !== 'quickbuilder'">/월</span>
              </div>
            </div>
            <div class="lp-divider-gray3 padding-top-16"></div>

            <div class="flex-between padding-top-16">
              <div class="subtitle7">VAT 10% 별도</div>
              <div>
                <span class="body4-bold">+{{ vat | currencyNum }}</span>
                <span class="body4 main">원</span>
              </div>
            </div>


            <div class="lp-divider-gray1 padding-top-56"></div>
            <div class="flex-between padding-top-20">
              <div class="body3-medium">총 금액</div>
              <div>
                <span class="h6">{{ totalPrice | currencyNum }}</span>
                <span class="body4 main">원</span>
              </div>
            </div>
            <button class="button is-primary margin-top-16" style="width:100%" @click="clickOrder">결제하기</button>
          </div>
        </div>
      </div>
      <div class="mobile"></div>
    </div>
  </div>
</template>
<script>
  import SelectHosting from "../component/SelectHosting";
  import SelectCareService from "../component/SelectCareService";
  import OrderMixin from "../../mixins/OrderMixin";
  import PageMixin from "../../mixins/PageMixin";
  export default {
    name: "ServiceOrder",
    mixins: [
      OrderMixin,
      PageMixin
    ],
    components: {
      SelectCareService,
      SelectHosting
    },
    created() {
      this.login_required = true;
      this.init();
    },
    mounted() {
      this.$nextTick(()=>{
        this.ev = ()=> {
          let el = document.getElementById('service-info');
          if(el && el.getClientRects().length > 0) {
            let top = el.getClientRects()[0].top;
            if(top < 80) {
              this.sticky = 'sticky';
              let infoEl = document.getElementById('info').getClientRects()[0];
              if((infoEl.top - 300) < 80) {
                this.sticky = 'sticky-end';
              } else {
                this.sticky = 'sticky';
              }
            } else {
              this.sticky = '';
            }
          }
        };
        window.addEventListener('scroll', this.ev);
      })
    },
    data() {
      return {
        ev: undefined,
        sticky: '',
        sbOption: [
          {
            name: '상품',
            type: 'conversions'
          },
          {
            name: '추가 플러그인',
            type: 'plugins'
          }
        ],
        order: {
          type: 'quickbuilder',
          key: '',
          totalPrice: 7350000,
          subscribe_price: 0,
          hosting: undefined,
          care_service: undefined,
          service_use_price: undefined,
          service_name: '',
          name: '',
          phone: '',
          email: ''
        }
      }
    },
    beforeDestroy() {
      document.getElementById('ch-plugin').style.display = '';
    },
    methods: {
      openPreview() {
        let url = `https://sbpreview.launchpack.co.kr/super_builder/preview?key=${this.order.key}&id=${this.order.service.id}&device=mobile&package=${this.order.service.conversions[0].key}`;
        window.open(url, '_blank', 'width=434, height=736, top=50, left=100, fullscreen=no, ' +
          'menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
      },
      onKeyupServiceId(e) {
        // eslint-disable-next-line no-useless-escape
        let regExp = /[\{\}\[\]\/?_.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/gi;
        // 특수문자 예외처리
        if (regExp.test(e.target.value)) {
          this.order.key = this.order.key.replace(regExp, '');
        } else if (e.target.value.length > 0 && /[a-zA-Z]/.test(e.target.value[0])) {
          this.order.key = e.target.value;
        } else {
          this.order.key = '';
        }
        this.order.key = this.order.key.toLowerCase();
      },
      init() {
        setTimeout(()=>{
          document.getElementById('ch-plugin').style.display = 'none';
        },500);
        let basket = this.$store.getters.basket;
        if(basket === undefined) {
          this.toast('올바른 접근이 아닙니다.');
          this.$router.back();
          return;
        }

        this.order.type = basket.type;
        // 슈퍼빌더 결제 처리
        if(basket.type === 'quickbuilder') {
          this.order.key = basket.service.key;
          this.order.totalPrice = basket.price;
          this.order.service = basket.service.service;
          this.order.service.id = basket.service.id;
        }
        // 디자인마켓
        else if(basket.type === 'theme') {
          this.order.order_product = basket.order_product;
          this.order.date_unit = basket.date_unit;
          // this.order.service_use_price = this.order.order_product.product.service_use_price
          this.order.subscribe_price = basket.subscribe_price;
          this.order.subscribePrice = basket.subscribe_price;
          this.order.totalPrice = this.order.order_product.total_price + (this.order.order_product.total_price/10);
          setTimeout(()=>{
            this.order.theme_type = this.order.order_product.order_product_option.filter(opt => {
              console.log(opt,this.optionName(opt))
              return this.optionName(opt).indexOf('수정 개발') > -1;
            }).length > 0 ? 'design' : 'theme';
          },1000);
        }

        this.order.name = this.user.name;
        this.order.email = this.user.email;
        this.order.phone = this.user.phone;
      },
      clickOrder() {
        let basket = this.cloneItem(this.order);
        if(this.order.type==='theme') {
          let key = this.user.username.split('@')[0]
          basket.key = key
          basket.service_name = key
          basket.title = basket.order_product.product.name;
          basket.price = this.totalPrice;
        } else if(this.order.type ==='quickbuilder') {
          basket.title = this.order.service.service_name;
          basket.price = this.totalPrice;

        }
        this.$store.commit('setBasket', basket);
        this.$router.replace('service_account');
      }
    },
    computed: {
      conversionPrice() {
        let price = 0;
        if(this.order.type==='quickbuilder') {
          this.order.service.conversions.forEach(i => {
            if(i.add_option) {
              price += i.option.price.price;
            } else {
              price += i.price.price;
            }
          });
        }
        return price;
      },
      pluginPrice() {
        let price = 0;
        if(this.order.type==='quickbuilder') {
          this.order.service.plugins.forEach(i => {
            price += i.price.price;
          });
        }
        return price;
      },
      hostingPrice() {
        return this.order.hosting ? this.order.hosting.price : 0;
      },
      carePrice() {
        return this.order.care_service ? this.order.care_service.price : 0;
      },
      totalPrice() {
        let price = this.hostingPrice + this.carePrice;
        price += price/10;
        price += this.order.totalPrice;
        return price;
      },
      vat() {
        return this.totalPrice * 1/11;
      }
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .pc
    .service-info-title
      width 40%

    .service-info-content
      width 60%

    .price-info-wrapper
      border 1px solid $gray2
      border-radius 8px
      padding 24px
      box-shadow 0 10px 20px rgba(0, 0, 0, 0.05)
    .sticky
    .sticky-end
      left calc(50% + 288px)
      background-color white
      width 288px
    .sticky
      position fixed
      top 104px

    .sticky-end
      position absolute
      bottom 0

  .icon-device
    filter invert(54%) sepia(46%) saturate(5884%) hue-rotate(360deg) brightness(100%) contrast(110%)
    margin-right 12px
    vertical-align sub

  .order-notice
    background-color #f5f5f5
    border-radius 8px
    margin-top 12px
    margin-bottom 6px
    padding 8px 10px
</style>
